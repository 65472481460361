import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import * as icons from './icons'
import * as Constants from './constants'
import { GameTileExpandableRow } from './game-tile-expandable-row'
import { GameTileGrid } from './game-tile-grid'

const ShowMore = styled.div`
  ${css({
    fontSize: [2, null, 3],
    fontFamily: 'head',
    fontWeight: 'bold',
    color: 'game-show-more-color',
    backgroundColor: 'game-show-more-background',
    borderRadius: '25px',
    padding: '10px 15px',
  })};
  cursor: pointer;
  white-space: nowrap;
  z-index: 999999;
  position: relative;
`

export function GameTileSection(props) {
  const ref = React.useRef()
  const i18n = useI18n()
  const [items, updateItems] = React.useState([])
  const [shouldExpand, setShouldExpand] = React.useState(false)
  const [visibleGames, setVisibleGames] = React.useState(props.columns)

  function handleUpdateItems(item) {
    if (R.not(R.includes(item, items))) {
      updateItems(R.append(item, items))
    } else {
      updateItems(R.reject(R.equals(item), items))
    }
  }

  function handleExpand() {
    const isSmall = props.size === 'small'
    const outerPadding = Constants.isDesktop ? 48 : 32
    const tilePadding = 16
    const gameTileWidth = Constants.GameTileWidth[R.toUpper(props.size)]
    const calculatedWidth =
      !Constants.isDesktop && !isSmall
        ? gameTileWidth * Constants.GAME_TILE_MULTIPLIER
        : gameTileWidth
    const rowLength =
      ref.current &&
      Math.floor(
        (ref.current.getBoundingClientRect().width -
          outerPadding +
          tilePadding) /
          (calculatedWidth + tilePadding)
      )
    const minValue = {
      small: Constants.isDesktop ? R.max(rowLength, 1) : 2,
      medium: Constants.isDesktop ? R.max(rowLength, 1) : 2,
      large: Constants.isDesktop ? R.max(rowLength, 1) : 1,
    }

    setVisibleGames(minValue[props.size])
    setShouldExpand(props.columns > minValue[props.size] && props.columns !== 1)
  }

  React.useEffect(() => {
    handleExpand()
  }, [ref.current])

  React.useEffect(() => {
    window.addEventListener('resize', handleExpand)

    return () => window.removeEventListener('resize', handleExpand)
  }, [])

  if (props.layout === 'grid') {
    return (
      <React.Fragment>
        {i18n.hasTranslation(props.sectionTitle) && (
          <common.Box
            fontSize={5}
            fontFamily="head"
            fontWeight="bold"
            px={[1, 2]}
            pb={0}
            color="game-section-title"
          >
            {i18n.translate(props.sectionTitle)}
          </common.Box>
        )}
        <common.Space px={[1, 2]} py={0}>
          <GameTileGrid
            columns={props.columns}
            size={props.size}
            containWidth={props.containWidth}
          >
            {props.children({})}
          </GameTileGrid>
        </common.Space>
      </React.Fragment>
    )
  }

  // Hybrid; row that can expand into grid
  if (props.layout === 'row') {
    return (
      <React.Fragment>
        <common.Box
          alignItems="center"
          display="flex"
          justifyContent={
            i18n.hasTranslation(props.sectionTitle)
              ? 'space-between'
              : 'flex-end'
          }
          px={[1, 2]}
          py={0}
        >
          {i18n.hasTranslation(props.sectionTitle) && (
            <common.Box
              fontSize={5}
              fontFamily="head"
              fontWeight="bold"
              color="game-section-title"
            >
              {i18n.translate(props.sectionTitle)}
            </common.Box>
          )}
          {props.layout === 'row' && shouldExpand && (
            <ShowMore onClick={() => handleUpdateItems(props.id)}>
              {R.includes(props.id, items) ? (
                <React.Fragment>
                  {i18n.translate('game-tile-section.show-less')}{' '}
                  <icons.ExpandLess />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {i18n.translate('game-tile-section.show-more')}{' '}
                </React.Fragment>
              )}
            </ShowMore>
          )}
        </common.Box>

        <GameTileExpandableRow
          ref={ref}
          columns={props.columns}
          expand={R.includes(props.id, items)}
          extraSpacing={props.extraSpacing}
          size={props.size}
        >
          <common.Space px={[1, 2]}>
            <GameTileGrid
              columns={props.columns}
              size={props.size}
              containWidth={props.containWidth}
            >
              {props.children({
                visibleGames: R.includes(props.id, items)
                  ? props.columns
                  : visibleGames,
              })}
            </GameTileGrid>
          </common.Space>
        </GameTileExpandableRow>
      </React.Fragment>
    )
  }

  return props.children({})
}

GameTileSection.propTypes = {
  id: PropTypes.string,
  children: PropTypes.func,
  columns: PropTypes.number,
  dynamicTile: PropTypes.bool,
  expand: PropTypes.bool,
  layout: PropTypes.oneOf(['grid', 'row']),
  containWidth: PropTypes.bool,
  sectionTitle: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}
